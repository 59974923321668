import styled from 'styled-components';

export const ImageDiv = styled.div`
  background-color: var(--primary);
  display: flex;
  height: 100px;
  justify-content: flex-end;
  padding-right: 15%;
  width: 100%;
  @media screen and (max-width: 677px) {
    padding-right: 0%;
  }
`;

export const Logo = styled.img`
  width: 300px;
  align-self: flex-end;
  justify-self: flex-end;

  @media screen and (max-width: 677px) {
    width: 200px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: '35px';
  gap: 135px;

  @media screen and (max-width: 677px) {
    gap: 50px;
  }
`;

export const Anchor = styled.a`
  text-align: center;
  margin: unset;
  padding: unset;
  text-decoration: unset;
  width: min(50%, 200px);
`;
